










































// Core
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

// Store
import { userNamespace } from '@store/user'

// Types
import { TaskActions, TaskMutations } from '@/store/task/types'
import { UserActionTypes } from '@/store/user/Types'

// Interfaces
import { Paginate, RequestParams } from '@/store/interfaces'
import { Team, TeamState } from '@store/team/interfaces'
import { Task } from '@store/task/interfaces'

// Modules
const NSTask = namespace('taskModule')
const NSTeam = namespace('teamModule')

@Component({
  name: 'DialogTaskInfo',
})
export default class DialogTaskInfoComponent extends Vue {
  @PropSync('visible', Boolean) private _visible!: boolean

  @Prop({
    type: Object,
    default: () => ({}),
  })
  private task!: Task

  @userNamespace.State('selectedProject')
  private selectedProject!: number

  @NSTeam.State((state: TeamState) => state.team.data)
  private team!: Team[]

  @NSTask.Action(TaskActions.A_FETCH_TASKS)
  private fetchTasks!: (params?: RequestParams) => Promise<Paginate<Task>>

  @NSTask.Mutation(TaskMutations.M_CLEAR_TASKS)
  private clearTasks!: () => void

  @userNamespace.Action(UserActionTypes.A_FINISH_TASK_OUT)
  private finishTask!: (params) => Promise<any>

  private get title(): string {
    if (this.task) {
      if (this.task.type.id === 1) {
        return 'Входящая задача'
      } else {
        return 'Исходящая задача'
      }
    } else {
      return ''
    }
  }

  private get assignee(): string {
    if (this.task.assigneeId) {
      const [assignee] = this.team.filter(t => t.id === this.task.assigneeId)

      return `${assignee.surname} ${assignee.name[0]}.`
    } else {
      return ''
    }
  }

  private handleCompleteTask() {
    // this.showFormAddTask = false
    this.finishTask({ taskID: this.task.id, selectedProject: this.selectedProject })
      .then(() => {
        this.$notify({
          title: 'Выполнено',
          message: 'Действие успешно выполнено',
          type: 'success',
        })

        this.clearTasks()
        this.fetchTasks({
          projectId: this.selectedProject,
        })

        this._visible = false
      })
      .catch(error => {
        this.$notify.error({
          title: 'Ошибка',
          message: error.response.data.message,
        })
      })
  }
}
