import { render, staticRenderFns } from "./DialogTaskInfo.vue?vue&type=template&id=013f78dc&scoped=true&lang=pug&"
import script from "./DialogTaskInfo.vue?vue&type=script&lang=ts&"
export * from "./DialogTaskInfo.vue?vue&type=script&lang=ts&"
import style0 from "./DialogTaskInfo.vue?vue&type=style&index=0&id=013f78dc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "013f78dc",
  null
  
)

export default component.exports